import React, {
  FunctionComponent,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Alternative } from "@lysaab/ui-2/components/input/InputAPI";
import {
  LysaFormRef,
  Form,
  RequiredValidator,
  TextInput,
  Select,
  Card,
  CountrySelect,
  Language,
  Button,
} from "@lysaab/ui-2";
import {
  PepContext,
  Role,
  MilitaryRank,
  PepType,
  EntityType,
} from "../PepContext";
import { useIntl, IntlShape, FormattedMessage } from "react-intl";
import {
  roleMessages,
  ambassadorMessages,
  governmentCompanyMessages,
  intCompanyMessages,
  militaryMessages,
} from "./RoleMessages";
import { formatPossessive } from "../../utils/Possession";

interface Props {
  language: Language;
  next: () => void;
}

export const RolePage: FunctionComponent<Props> = ({ language, next }) => {
  const pepContext = useContext(PepContext);
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const entityType = pepContext.state.legalEntityType || EntityType.PERSON;
  const type = pepContext.state.type;

  const roleAlternatives: Alternative<Role>[] = getRoleAlts(intl);
  const militaryAlternatives: Alternative<MilitaryRank>[] =
    getMilitaryAlts(intl);

  const nextPage = useCallback(() => {
    if (formRef.current && formRef.current.isValid) {
      next();
    }
  }, [formRef, next]);

  const resetRoleFields = useCallback(() => {
    pepContext.setState({
      ambassadorCountry: undefined,
      governmentCompany: undefined,
      governmentCompanyRole: undefined,
      internationalOrganisation: undefined,
      internationalOrganisationRole: undefined,
      militaryRank: undefined,
    });
  }, [pepContext]);

  if (!type || type === PepType.NOT_PEP) {
    return null;
  }

  return (
    <Form onSubmit={() => nextPage()} lysaFormRef={formRef}>
      <Card>
        <Select
          label={intl.formatMessage(roleMessages.header, {
            entityType,
            pepType: type,
            relation:
              pepContext.state.type === PepType.COLLEAGUE
                ? pepContext.state.businessRelation
                : pepContext.state.relationToPEP,
            name: pepContext.state.ownerName,
            possessiveName: formatPossessive(
              pepContext.state.ownerName,
              intl.locale
            ),
          })}
          placeholder={intl.formatMessage(roleMessages.placeholder)}
          alternatives={roleAlternatives}
          value={roleAlternatives.find(
            (alt) => alt.value === pepContext.state.role
          )}
          onChange={(event) => {
            pepContext.setState({ role: event.value as Role });
            resetRoleFields();
          }}
          validators={[
            new RequiredValidator(intl.formatMessage(roleMessages.required)),
          ]}
        />

        {pepContext.state.role && pepContext.state.role === Role.AMBASSADOR ? (
          <CountrySelect
            label={intl.formatMessage(ambassadorMessages.header, {
              entityType,
              pepType: type,
              relation:
                pepContext.state.type === PepType.COLLEAGUE
                  ? pepContext.state.businessRelation
                  : pepContext.state.relationToPEP,
              name: pepContext.state.ownerName,
            })}
            onChange={(event) =>
              pepContext.setState({
                ambassadorCountry: event.value ? event.value : undefined,
              })
            }
            value={pepContext.state.ambassadorCountry}
            validators={[
              new RequiredValidator(
                intl.formatMessage(ambassadorMessages.required)
              ),
            ]}
            language={language}
            placeholder={intl.formatMessage(ambassadorMessages.placeholder)}
          />
        ) : null}

        {pepContext.state.role &&
        pepContext.state.role === Role.GOVERNMENT_COMPANY ? (
          <React.Fragment>
            <TextInput
              label={intl.formatMessage(governmentCompanyMessages.nameHeader, {
                entityType,
                pepType: type,
                relation:
                  pepContext.state.type === PepType.COLLEAGUE
                    ? pepContext.state.businessRelation
                    : pepContext.state.relationToPEP,
                name: pepContext.state.ownerName,
              })}
              value={pepContext.state.governmentCompany || ""}
              onChange={(value) => {
                pepContext.setState({ governmentCompany: value });
              }}
              validators={[
                new RequiredValidator(
                  intl.formatMessage(governmentCompanyMessages.nameRequired)
                ),
              ]}
            />
            <TextInput
              label={intl.formatMessage(governmentCompanyMessages.roleHeader, {
                entityType,
                pepType: type,
                relation:
                  pepContext.state.type === PepType.COLLEAGUE
                    ? pepContext.state.businessRelation
                    : pepContext.state.relationToPEP,
                name: pepContext.state.ownerName,
              })}
              value={pepContext.state.governmentCompanyRole || ""}
              onChange={(value) => {
                pepContext.setState({
                  governmentCompanyRole: value,
                });
              }}
              validators={[
                new RequiredValidator(
                  intl.formatMessage(governmentCompanyMessages.roleRequired)
                ),
              ]}
            />
          </React.Fragment>
        ) : null}

        {pepContext.state.role &&
        pepContext.state.role ===
          Role.HIGH_RANKING_POSITION_INTERNALTIONAL_CORP ? (
          <React.Fragment>
            <TextInput
              label={intl.formatMessage(intCompanyMessages.nameHeader, {
                entityType,
                pepType: type,
                relation:
                  pepContext.state.type === PepType.COLLEAGUE
                    ? pepContext.state.businessRelation
                    : pepContext.state.relationToPEP,
                name: pepContext.state.ownerName,
              })}
              value={pepContext.state.internationalOrganisation || ""}
              onChange={(value) => {
                pepContext.setState({ internationalOrganisation: value });
              }}
              validators={[
                new RequiredValidator(
                  intl.formatMessage(intCompanyMessages.nameRequired)
                ),
              ]}
            />
            <TextInput
              label={intl.formatMessage(intCompanyMessages.roleHeader, {
                entityType,
                pepType: type,
                relation:
                  pepContext.state.type === PepType.COLLEAGUE
                    ? pepContext.state.businessRelation
                    : pepContext.state.relationToPEP,
                name: pepContext.state.ownerName,
              })}
              value={pepContext.state.internationalOrganisationRole || ""}
              onChange={(value) => {
                pepContext.setState({
                  internationalOrganisationRole: value,
                });
              }}
              validators={[
                new RequiredValidator(
                  intl.formatMessage(intCompanyMessages.roleRequired)
                ),
              ]}
            />
          </React.Fragment>
        ) : null}

        {pepContext.state.role &&
        pepContext.state.role === Role.MILITARY_OFFICER ? (
          <Select
            label={intl.formatMessage(militaryMessages.header, {
              entityType,
              pepType: type,
              relation:
                pepContext.state.type === PepType.COLLEAGUE
                  ? pepContext.state.businessRelation
                  : pepContext.state.relationToPEP,
              name: pepContext.state.ownerName,
              possessiveName: formatPossessive(
                pepContext.state.ownerName,
                intl.locale
              ),
            })}
            placeholder={intl.formatMessage(militaryMessages.placeholder)}
            alternatives={militaryAlternatives}
            value={militaryAlternatives.find(
              (alt) => alt.value === pepContext.state.militaryRank
            )}
            onChange={(event) => {
              pepContext.setState({
                militaryRank: event.value as MilitaryRank,
              });
            }}
            validators={[
              new RequiredValidator(
                intl.formatMessage(militaryMessages.required)
              ),
            ]}
          />
        ) : null}
      </Card>
      <Button
        block
        type="submit"
        label={<FormattedMessage id="pep.role.next" />}
      />
    </Form>
  );
};

export function getRoleAlts(intl: IntlShape): Alternative<Role>[] {
  return [
    {
      text: intl.formatMessage(roleMessages[Role.HEAD_OF_GOVERNMENT]),
      value: Role.HEAD_OF_GOVERNMENT,
    },
    {
      text: intl.formatMessage(roleMessages[Role.MINISTER]),
      value: Role.MINISTER,
    },
    {
      text: intl.formatMessage(roleMessages[Role.PARLIMENT_MEMBER]),
      value: Role.PARLIMENT_MEMBER,
    },
    {
      text: intl.formatMessage(roleMessages[Role.CENTRAL_BANK_MEMBER]),
      value: Role.CENTRAL_BANK_MEMBER,
    },
    {
      text: intl.formatMessage(roleMessages[Role.BOARD_MEMBER_POLITICAL_PARTY]),
      value: Role.BOARD_MEMBER_POLITICAL_PARTY,
    },
    {
      text: intl.formatMessage(roleMessages[Role.JUDGE_SUPREME_COURT]),
      value: Role.JUDGE_SUPREME_COURT,
    },
    {
      text: intl.formatMessage(roleMessages[Role.JUDGE_OTHER_HIGH_COURT]),
      value: Role.JUDGE_OTHER_HIGH_COURT,
    },
    {
      text: intl.formatMessage(roleMessages[Role.REVISION_INSTITUTE]),
      value: Role.REVISION_INSTITUTE,
    },
    {
      text: intl.formatMessage(roleMessages[Role.AMBASSADOR]),
      value: Role.AMBASSADOR,
    },
    {
      text: intl.formatMessage(roleMessages[Role.MILITARY_OFFICER]),
      value: Role.MILITARY_OFFICER,
    },
    {
      text: intl.formatMessage(roleMessages[Role.GOVERNMENT_COMPANY]),
      value: Role.GOVERNMENT_COMPANY,
    },
    {
      text: intl.formatMessage(roleMessages[Role.EU_HIGH_RANKING_POSITION]),
      value: Role.EU_HIGH_RANKING_POSITION,
    },
    {
      text: intl.formatMessage(
        roleMessages[Role.HIGH_RANKING_POSITION_INTERNALTIONAL_CORP]
      ),
      value: Role.HIGH_RANKING_POSITION_INTERNALTIONAL_CORP,
    },
  ];
}

export function getMilitaryAlts(intl: IntlShape): Alternative<MilitaryRank>[] {
  return [
    {
      text: intl.formatMessage(militaryMessages[MilitaryRank.GENERAL]),
      value: MilitaryRank.GENERAL,
    },
    {
      text: intl.formatMessage(
        militaryMessages[MilitaryRank.LIEUTENANT_GENERAL]
      ),
      value: MilitaryRank.LIEUTENANT_GENERAL,
    },
    {
      text: intl.formatMessage(militaryMessages[MilitaryRank.MAJOR_GENERAL]),
      value: MilitaryRank.MAJOR_GENERAL,
    },
    {
      text: intl.formatMessage(militaryMessages[MilitaryRank.ADMIRAL]),
      value: MilitaryRank.ADMIRAL,
    },
    {
      text: intl.formatMessage(militaryMessages[MilitaryRank.VICE_ADMIRAL]),
      value: MilitaryRank.VICE_ADMIRAL,
    },
    {
      text: intl.formatMessage(militaryMessages[MilitaryRank.REAR_ADMIRAL]),
      value: MilitaryRank.REAR_ADMIRAL,
    },
  ];
}
