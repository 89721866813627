import { WorldCountry } from "@lysaab/ui-2";
import React, { useReducer } from "react";

export enum EntityType {
  PERSON = "PERSON",
  CORPORATION = "CORPORATION",
}

export enum PepType {
  NOT_PEP = "NOT_PEP",
  ME = "ME",
  RELATIVE = "RELATIVE",
  COLLEAGUE = "COLLEAGUE",
}

export enum PepStatus {
  ONGOING = "ONGOING",
  ENDED = "ENDED",
}

export enum Role {
  HEAD_OF_GOVERNMENT = "HEAD_OF_GOVERNMENT",
  MINISTER = "MINISTER",
  PARLIMENT_MEMBER = "PARLIMENT_MEMBER",
  CENTRAL_BANK_MEMBER = "CENTRAL_BANK_MEMBER",
  BOARD_MEMBER_POLITICAL_PARTY = "BOARD_MEMBER_POLITICAL_PARTY",
  JUDGE_SUPREME_COURT = "JUDGE_SUPREME_COURT",
  JUDGE_OTHER_HIGH_COURT = "JUDGE_OTHER_HIGH_COURT",
  REVISION_INSTITUTE = "REVISION_INSTITUTE",
  AMBASSADOR = "AMBASSADOR",
  MILITARY_OFFICER = "MILITARY_OFFICER",
  GOVERNMENT_COMPANY = "GOVERNMENT_COMPANY",
  EU_HIGH_RANKING_POSITION = "EU_HIGH_RANKING_POSITION",
  HIGH_RANKING_POSITION_INTERNALTIONAL_CORP = "HIGH_RANKING_POSITION_INTERNALTIONAL_CORP",
}

export enum Relation {
  MARRIED_PARTNER = "MARRIED_PARTNER",
  COLIVING_PARTNER = "COLIVING_PARTNER",
  CHILD = "CHILD",
  PARENT = "PARENT",
  CHILD_MARRIED_PARTNER = "CHILD_MARRIED_PARTNER",
  CHILD_COLIVING_PARTNER = "CHILD_COLIVING_PARTNER",
}

export enum BusinessRelation {
  CO_OWNERSHIP = "CO_OWNERSHIP",
  CLOSE_CONNECTION = "CLOSE_CONNECTION",
  COMPANY_FOR_PEP = "COMPANY_FOR_PEP",
}

export enum MilitaryRank {
  GENERAL = "GENERAL",
  LIEUTENANT_GENERAL = "LIEUTENANT_GENERAL",
  MAJOR_GENERAL = "MAJOR_GENERAL",
  ADMIRAL = "ADMIRAL",
  VICE_ADMIRAL = "VICE_ADMIRAL",
  REAR_ADMIRAL = "REAR_ADMIRAL",
}

// Types for the state
export interface PepState {
  legalEntityType?: EntityType;
  type?: PepType;
  role?: Role;
  country?: WorldCountry;
  ended?: Date;
  ambassadorCountry?: WorldCountry;
  militaryRank?: MilitaryRank;
  governmentCompany?: string;
  governmentCompanyRole?: string;
  internationalOrganisation?: string;
  internationalOrganisationRole?: string;
  relationToPEP?: Relation;
  nameOfPEP?: string;
  birthdayOfPEP?: Date;
  tin?: string;
  businessRelation?: BusinessRelation;
  locked?: boolean;

  isOngoing?: PepStatus;
  isLocalCountry?: string;
  ownerName?: string;
}

export const EMPTY_PEP_STATE: PepState = {
  legalEntityType: undefined,
  type: undefined,
  role: undefined,
  country: undefined,
  ended: undefined,
  ambassadorCountry: undefined,
  militaryRank: undefined,
  governmentCompany: undefined,
  governmentCompanyRole: undefined,
  internationalOrganisation: undefined,
  internationalOrganisationRole: undefined,
  relationToPEP: undefined,
  nameOfPEP: undefined,
  birthdayOfPEP: undefined,
  tin: undefined,
  businessRelation: undefined,
  locked: undefined,

  isOngoing: undefined,
  isLocalCountry: undefined,
  ownerName: undefined,
};

// All types for the context. This includes setter methods
export interface PepContextProps {
  state: PepState;
  setState: (newState: Partial<PepState>) => void;
}

// Create the context. (This will just create a template)
export const PepContext = React.createContext<PepContextProps>(
  {} as PepContextProps
);

/**
 * A Higher Order Component. This is used to wrap classes so that the context is injected
 * There is quite a bit TS magic here. This is so that the internal props off the component
 * will be stripped of the context props so that the external props will only show the relevant
 * props
 */
export const withPep =
  <P extends object>(
    Component: React.ComponentType<React.PropsWithChildren<P>>
  ): React.FC<React.PropsWithChildren<Omit<P, keyof PepContextProps>>> =>
  (props) =>
    (
      <PepContext.Consumer>
        {(contextProps) => <Component {...(props as P)} {...contextProps} />}
      </PepContext.Consumer>
    );

function stateReducer(state: PepState, newState: Partial<PepState>) {
  return { ...state, ...newState };
}

export const PepContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [pep, setPep] = useReducer(stateReducer, {});

  return (
    <PepContext.Provider value={{ state: pep, setState: setPep }}>
      {children}
    </PepContext.Provider>
  );
};
