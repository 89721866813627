import React, {
  FunctionComponent,
  useRef,
  useCallback,
  useContext,
} from "react";
import {
  Form,
  RadioGroup,
  RequiredValidator,
  LysaFormRef,
  Datepicker,
  Card,
  CountrySelect,
  Language,
  WorldCountry,
  Button,
} from "@lysaab/ui-2";
import { Alternative } from "@lysaab/ui-2/components/input/InputAPI";
import { PepContext, PepStatus, EntityType, PepType } from "../PepContext";
import { useIntl, FormattedMessage, IntlShape } from "react-intl";
import {
  isOngoingMessages,
  endedMessages,
  isLocalCountryMessages,
  countrySelectionMessages,
  localCountryAltMessages,
  stateAltMessages,
} from "./StatusMessages";
import { formatPossessive } from "../../utils/Possession";

interface Props {
  countryName: string;
  countryCode: WorldCountry;
  language: Language;
  next: () => void;
}

export const StatusPage: FunctionComponent<Props> = ({
  countryName,
  countryCode,
  language,
  next,
}) => {
  const pepContext = useContext(PepContext);
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const stateAlternatives = getStateAlternatives(intl);
  const isLocalCountryAlternatives = getIsLocalCountryAlternatives(
    intl,
    countryName
  );
  const entityType = pepContext.state.legalEntityType || EntityType.PERSON;

  const nextPage = useCallback(() => {
    if (formRef.current && formRef.current.isValid) {
      next();
    }
  }, [formRef, next]);

  const updateIsLocalCountry = useCallback(
    (alternative: Alternative<string>) => {
      pepContext.setState({ isLocalCountry: alternative.value });

      if (alternative.value === "other") {
        pepContext.setState({ country: undefined });
      } else {
        pepContext.setState({ country: countryCode });
      }
    },
    [pepContext, countryCode]
  );

  const updateIsOngoing = useCallback(
    (alternative: Alternative<PepStatus>) => {
      pepContext.setState({ isOngoing: alternative.value });

      if (alternative.value === PepStatus.ONGOING) {
        pepContext.setState({ ended: undefined });
      }
    },
    [pepContext]
  );

  return (
    <div>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          nextPage();
        }}
      >
        <Card>
          <RadioGroup
            alternatives={stateAlternatives}
            header={intl.formatMessage(isOngoingMessages.header, {
              entityType,
              name: pepContext.state.ownerName,
              possessiveName: formatPossessive(
                pepContext.state.ownerName,
                intl.locale
              ),
              relation:
                pepContext.state.type === PepType.COLLEAGUE
                  ? pepContext.state.businessRelation
                  : pepContext.state.relationToPEP,
              pepType: pepContext.state.type,
            })}
            onChange={updateIsOngoing}
            value={stateAlternatives.find(
              (alt) => alt.value === pepContext.state.isOngoing
            )}
            validators={[
              new RequiredValidator(
                intl.formatMessage(isOngoingMessages.required)
              ),
            ]}
          />

          {pepContext.state.isOngoing &&
          pepContext.state.isOngoing === PepStatus.ENDED ? (
            <Datepicker
              label={intl.formatMessage(endedMessages.header)}
              selected={pepContext.state.ended}
              onChange={(event) => {
                // event has a type that says it can be [startDate, endDate]
                // but we know that will never be the case here
                pepContext.setState({ ended: (event as Date) || undefined });
              }}
              validators={[
                new RequiredValidator(
                  intl.formatMessage(endedMessages.required)
                ),
              ]}
              showMonthYearPicker
              dateFormat="yyyy-MM"
              maxDate={new Date()}
            />
          ) : null}
        </Card>

        <Card>
          <RadioGroup
            alternatives={isLocalCountryAlternatives}
            header={intl.formatMessage(isLocalCountryMessages.header, {
              possessiveName: formatPossessive(
                pepContext.state.ownerName,
                intl.locale
              ),
            })}
            onChange={updateIsLocalCountry}
            value={isLocalCountryAlternatives.find(
              (alt) => alt.value === pepContext.state.isLocalCountry
            )}
            validators={[
              new RequiredValidator(
                intl.formatMessage(isLocalCountryMessages.required)
              ),
            ]}
          />

          {pepContext.state.isLocalCountry &&
          pepContext.state.isLocalCountry === "other" ? (
            <CountrySelect
              label={intl.formatMessage(countrySelectionMessages.header, {
                entityType,
                name: pepContext.state.ownerName,
                possessiveName: formatPossessive(
                  pepContext.state.ownerName,
                  intl.locale
                ),
                relation:
                  pepContext.state.type === PepType.COLLEAGUE
                    ? pepContext.state.businessRelation
                    : pepContext.state.relationToPEP,
                pepType: pepContext.state.type,
              })}
              onChange={(event) =>
                pepContext.setState({
                  country: event.value ? event.value : undefined,
                })
              }
              validators={[
                new RequiredValidator(
                  intl.formatMessage(countrySelectionMessages.required)
                ),
              ]}
              value={pepContext.state.country}
              omitList={[countryCode]}
              language={language}
              placeholder={intl.formatMessage(
                countrySelectionMessages.placeholder
              )}
            />
          ) : null}
        </Card>

        <Button
          label={<FormattedMessage id="pep.status.next" />}
          block
          type="submit"
        />
      </Form>
    </div>
  );
};

function getIsLocalCountryAlternatives(
  intl: IntlShape,
  countryName: string
): Alternative<string>[] {
  return [
    {
      text: intl.formatMessage(localCountryAltMessages.localCountry, {
        countryName,
      }),
      value: "local",
    },
    {
      text: intl.formatMessage(localCountryAltMessages.otherCountry),
      value: "other",
    },
  ];
}

function getStateAlternatives(intl: IntlShape): Alternative<PepStatus>[] {
  return [
    {
      text: intl.formatMessage(stateAltMessages[PepStatus.ONGOING]),
      value: PepStatus.ONGOING,
    },
    {
      text: intl.formatMessage(stateAltMessages[PepStatus.ENDED]),
      value: PepStatus.ENDED,
    },
  ];
}
