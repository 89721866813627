import { PepState, PepType, EntityType, PepStatus } from "../pages/PepContext";
import { DateTime } from "luxon";

export interface PepRequest
  extends Omit<
    PepState,
    "isLocalCountry" | "isOngoing" | "ended" | "birthdayOfPEP"
  > {
  ended?: string;
  birthdayOfPEP?: string;
}

/**
 * Do some minor preprocessing before we are ready to send everything to the
 * server
 */
export function stateToRequest(pepState: PepState): PepRequest {
  const state = { ...pepState };
  delete state.isLocalCountry;
  delete state.isOngoing;
  delete state.ownerName;
  if (state.legalEntityType === EntityType.PERSON) {
    delete state.tin;
  }
  return {
    ...state,
    country: state.type === PepType.NOT_PEP ? undefined : state.country,
    ended: state.ended
      ? DateTime.fromJSDate(state.ended).toFormat("yyyy-MM")
      : undefined,
    birthdayOfPEP: state.birthdayOfPEP
      ? DateTime.fromJSDate(state.birthdayOfPEP).toFormat("yyyy-MM-dd")
      : undefined,
  };
}

export function requestToState(
  { ended, birthdayOfPEP, ...serverData }: PepRequest,
  countryCode: string
): PepState {
  const state: PepState = { ...serverData };
  if (ended) {
    state.ended = DateTime.fromFormat(ended, "yyyy-MM").toJSDate();
  }
  if (birthdayOfPEP) {
    state.birthdayOfPEP = DateTime.fromFormat(
      birthdayOfPEP,
      "yyyy-MM-dd"
    ).toJSDate();
  }
  state.isLocalCountry = state.country === countryCode ? "local" : "other";
  state.isOngoing = state.ended ? PepStatus.ENDED : PepStatus.ONGOING;
  return state;
}
