export function formatPossessive(text: string | undefined, locale: string) {
  if (!text) {
    return;
  }

  const language = locale.substr(0, 2);

  if (language === "sv") {
    return formatSwedish(text);
  } else if (language === "en") {
    return formatEnglish(text);
  }

  return text;
}

function formatSwedish(text: string) {
  if (
    text[text.length - 1] !== "s" &&
    text[text.length - 1] !== "z" &&
    text[text.length - 1] !== "x"
  ) {
    text += "s";
  }
  return text;
}

// There are no proper rules for English
// Jess -> Jess's
// Charles -> Charles's or Charles'
// Jesus -> Jesus'
// Martinez -> Martinez's or Martinez'
// Alex -> Alex's
// Hendrix -> Hendrix'
function formatEnglish(text: string) {
  if (text[text.length - 1] === "s") {
    return text + "'";
  }

  return text + "'s";
}