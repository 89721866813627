import React, {
  useContext,
  useRef,
  useCallback,
  FunctionComponent,
} from "react";
import {
  PepContext,
  PepType,
  BusinessRelation,
  Relation,
  EntityType,
  PepContextProps,
} from "../PepContext";
import {
  LysaFormRef,
  RadioGroup,
  Form,
  Alternative,
  RequiredValidator,
  TextInput,
  Datepicker,
  Button,
  Card,
} from "@lysaab/ui-2";
import { DateTime } from "luxon";
import { useIntl, IntlShape, FormattedMessage } from "react-intl";
import {
  relationMessages,
  businessRelationMessages,
  relativeNameMessages,
  birthdayMessages,
  colleagueNameMessages,
} from "./RelationMessages";
import { formatPossessive } from "../../utils/Possession";

const DATEPICKER_START_DATE = DateTime.fromISO("1970-01-01").toJSDate();

interface Props {
  next: () => void;
}

export const RelationPage: FunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const pepContext = useContext(PepContext);
  const formRef = useRef<LysaFormRef>();
  const entityType = pepContext.state.legalEntityType || EntityType.PERSON;
  const type = pepContext.state.type;

  const businessRelationAlternatives = getBusinessRelationAlternatives(
    intl,
    entityType,
    pepContext
  );
  const relationAlternatives = getRelationAlternatives(
    intl,
    entityType,
    pepContext
  );

  const nextPage = useCallback(() => {
    if (formRef.current && formRef.current.isValid) {
      next();
    }
  }, [formRef, next]);
  return (
    <Form onSubmit={() => nextPage()} lysaFormRef={formRef}>
      <Card>
        {type === PepType.RELATIVE ? (
          <RadioGroup
            alternatives={relationAlternatives}
            onChange={(alternative) => {
              pepContext.setState({
                relationToPEP: alternative.value,
              });
            }}
            value={relationAlternatives.find(
              (alt) => alt.value === pepContext.state.relationToPEP
            )}
            header={intl.formatMessage(relationMessages.header, {
              entityType,
              name: pepContext.state.ownerName,
              possessiveName: formatPossessive(
                pepContext.state.ownerName,
                intl.locale
              ),
            })}
            validators={[
              new RequiredValidator(
                intl.formatMessage(relationMessages.required)
              ),
            ]}
          />
        ) : null}
        {type === PepType.COLLEAGUE ? (
          <RadioGroup
            alternatives={businessRelationAlternatives}
            onChange={(alternative) => {
              pepContext.setState({
                businessRelation: alternative.value,
              });
            }}
            value={businessRelationAlternatives.find(
              (alt) => alt.value === pepContext.state.businessRelation
            )}
            header={intl.formatMessage(businessRelationMessages.header, {
              entityType,
              name: pepContext.state.ownerName,
            })}
            validators={[
              new RequiredValidator(
                intl.formatMessage(businessRelationMessages.required)
              ),
            ]}
          />
        ) : null}
        {pepContext.state.relationToPEP && type === PepType.RELATIVE ? (
          <React.Fragment>
            <TextInput
              label={intl.formatMessage(relativeNameMessages.header, {
                entityType,
                name: pepContext.state.ownerName,
                relation:
                  pepContext.state.type === PepType.COLLEAGUE
                    ? pepContext.state.businessRelation
                    : pepContext.state.relationToPEP,
                possessiveName: formatPossessive(
                  pepContext.state.ownerName,
                  intl.locale
                ),
              })}
              value={pepContext.state.nameOfPEP || ""}
              onChange={(value) => {
                pepContext.setState({ nameOfPEP: value });
              }}
              validators={[
                new RequiredValidator(
                  intl.formatMessage(relativeNameMessages.required)
                ),
              ]}
            />
            <Datepicker
              label={intl.formatMessage(birthdayMessages.header, {
                entityType,
                name: pepContext.state.ownerName,
                relation:
                  pepContext.state.type === PepType.COLLEAGUE
                    ? pepContext.state.businessRelation
                    : pepContext.state.relationToPEP,
                possessiveName: formatPossessive(
                  pepContext.state.ownerName,
                  intl.locale
                ),
              })}
              selected={pepContext.state.birthdayOfPEP}
              onChange={(event) => {
                // event has a type that says it can be [startDate, endDate]
                // but we know that will never be the case here
                pepContext.setState({
                  birthdayOfPEP: (event as Date) || undefined,
                });
              }}
              validators={[
                new RequiredValidator(
                  intl.formatMessage(birthdayMessages.required)
                ),
              ]}
              maxDate={new Date()}
              openToDate={
                pepContext.state.birthdayOfPEP || DATEPICKER_START_DATE
              }
            />
          </React.Fragment>
        ) : null}

        {pepContext.state.businessRelation && type === PepType.COLLEAGUE ? (
          <React.Fragment>
            <TextInput
              label={intl.formatMessage(colleagueNameMessages.header, {
                entityType,
                name: pepContext.state.ownerName,
                relation:
                  pepContext.state.type === PepType.COLLEAGUE
                    ? pepContext.state.businessRelation
                    : pepContext.state.relationToPEP,
                possessiveName: formatPossessive(
                  pepContext.state.ownerName,
                  intl.locale
                ),
              })}
              value={pepContext.state.nameOfPEP || ""}
              onChange={(value) => {
                pepContext.setState({ nameOfPEP: value });
              }}
              validators={[
                new RequiredValidator(
                  intl.formatMessage(colleagueNameMessages.required)
                ),
              ]}
            />
          </React.Fragment>
        ) : null}
      </Card>
      <Button
        block
        type="submit"
        label={<FormattedMessage id="pep.relation.next" />}
      />
    </Form>
  );
};
function getRelationAlternatives(
  intl: IntlShape,
  entityType: EntityType,
  pepContext: PepContextProps
): Alternative<Relation>[] {
  return [
    {
      text: intl.formatMessage(relationMessages[Relation.MARRIED_PARTNER], {
        entityType,
        name: pepContext.state.ownerName,
      }),
      value: Relation.MARRIED_PARTNER,
    },
    {
      text: intl.formatMessage(relationMessages[Relation.COLIVING_PARTNER], {
        entityType,
        name: pepContext.state.ownerName,
      }),
      value: Relation.COLIVING_PARTNER,
    },
    {
      text: intl.formatMessage(relationMessages[Relation.CHILD], {
        entityType,
        name: pepContext.state.ownerName,
      }),
      value: Relation.CHILD,
    },
    {
      text: intl.formatMessage(relationMessages[Relation.PARENT], {
        entityType,
        name: pepContext.state.ownerName,
      }),
      value: Relation.PARENT,
    },
    {
      text: intl.formatMessage(
        relationMessages[Relation.CHILD_MARRIED_PARTNER],
        {
          entityType,
          name: pepContext.state.ownerName,
        }
      ),
      value: Relation.CHILD_MARRIED_PARTNER,
    },
    {
      text: intl.formatMessage(
        relationMessages[Relation.CHILD_COLIVING_PARTNER],
        {
          entityType,
          name: pepContext.state.ownerName,
        }
      ),
      value: Relation.CHILD_COLIVING_PARTNER,
    },
  ];
}

function getBusinessRelationAlternatives(
  intl: IntlShape,
  entityType: EntityType,
  pepContext: PepContextProps
): Alternative<BusinessRelation>[] {
  return [
    {
      text: intl.formatMessage(
        businessRelationMessages[BusinessRelation.CO_OWNERSHIP],
        {
          entityType,
          name: pepContext.state.ownerName,
        }
      ),
      value: BusinessRelation.CO_OWNERSHIP,
    },
    {
      text: intl.formatMessage(
        businessRelationMessages[BusinessRelation.CLOSE_CONNECTION],
        {
          entityType,
          name: pepContext.state.ownerName,
        }
      ),
      value: BusinessRelation.CLOSE_CONNECTION,
    },
    {
      text: intl.formatMessage(
        businessRelationMessages[BusinessRelation.COMPANY_FOR_PEP],
        {
          entityType,
          name: pepContext.state.ownerName,
        }
      ),
      value: BusinessRelation.COMPANY_FOR_PEP,
    },
  ];
}
