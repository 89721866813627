import React, { FunctionComponent } from "react";
import "./PepAnswers.scss";
import { PepRequest } from "../model/PepModel";
import { getRoleAlts, getMilitaryAlts } from "../pages/RolePage/RolePage";
import { PepType } from "../pages/PepContext";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { messages as isPepMessages } from "../pages/IsPep/IsPepMessages";
import {
  relationMessages,
  businessRelationMessages,
} from "../pages/RelationPage/RelationMessages";

interface Props {
  answer: PepRequest;
}

const messages = defineMessages({
  whoIsPepLabel: {
    id: "pep.answers.who.label",
  },
  relationLabel: {
    id: "pep.answers.relationship.label",
  },
  businessRelationLabel: {
    id: "pep.answers.businessRelationship.label",
  },
  nameOfPepLabel: {
    id: "pep.answers.nameOfPep.label",
  },
  birthdayOfPEPLabel: {
    id: "pep.answers.birthdayOfPEP.label",
  },
  statusLabel: {
    id: "pep.answers.status.label",
  },
  statusEndedValue: {
    id: "pep.answers.statusEnded.value",
  },
  statusOngoingValue: {
    id: "pep.answers.statusOngoing.value",
  },
  endedLabel: {
    id: "pep.answers.endedStatus.label",
  },
  roleLabel: {
    id: "pep.answers.role.label",
  },
  countryLabel: {
    id: "pep.answers.country.label",
  },
  ambassadorCountryLabel: {
    id: "pep.answers.ambassadorCountry.label",
  },
  governmentCompanyNameLabel: {
    id: "pep.answers.governmentCompanyName.label",
  },
  governmentCompanyRoleLabel: {
    id: "pep.answers.governmentCompanyRole.label",
  },
  internationalOrganisationNameLabel: {
    id: "pep.answers.companyName.label",
  },
  internationalOrganisationRoleLabel: {
    id: "pep.answers.companyRole.label",
  },
  militaryGradeLabel: {
    id: "pep.answers.militaryRank.label",
  },
});

export const PepAnswers: FunctionComponent<Props> = ({ answer }) => {
  const intl = useIntl();

  if (!answer.type || answer.type === PepType.NOT_PEP) {
    return (
      <div>
        <p>
          <FormattedMessage id="pep.answers.notpep" />
        </p>
      </div>
    );
  }

  const entityType = answer.legalEntityType;
  const role = getRoleAlts(intl).find((alt) => alt.value === answer.role);
  const militaryRank = getMilitaryAlts(intl).find(
    (alt) => alt.value === answer.militaryRank
  );

  return (
    <div>
      {answer.type && (
        <StaticInput
          label={intl.formatMessage(messages.whoIsPepLabel)}
          value={intl.formatMessage(isPepMessages[answer.type], {
            entityType,
            name: answer.ownerName,
          })}
        />
      )}
      {answer.relationToPEP && (
        <StaticInput
          label={intl.formatMessage(messages.relationLabel)}
          value={intl.formatMessage(relationMessages[answer.relationToPEP], {
            entityType,
            name: answer.ownerName,
          })}
        />
      )}
      {answer.businessRelation && (
        <StaticInput
          label={intl.formatMessage(messages.businessRelationLabel)}
          value={intl.formatMessage(
            businessRelationMessages[answer.businessRelation],
            { entityType, name: answer.ownerName }
          )}
        />
      )}
      {answer.nameOfPEP && (
        <StaticInput
          label={intl.formatMessage(messages.nameOfPepLabel)}
          value={answer.nameOfPEP}
        />
      )}
      {answer.birthdayOfPEP && (
        <StaticInput
          label={intl.formatMessage(messages.birthdayOfPEPLabel)}
          value={answer.birthdayOfPEP}
        />
      )}
      {answer.ended ? (
        <React.Fragment>
          <StaticInput
            label={intl.formatMessage(messages.statusLabel)}
            value={intl.formatMessage(messages.statusEndedValue)}
          />
          <StaticInput
            label={intl.formatMessage(messages.endedLabel)}
            value={answer.ended}
          />
        </React.Fragment>
      ) : (
        <StaticInput
          label={intl.formatMessage(messages.statusLabel)}
          value={intl.formatMessage(messages.statusOngoingValue)}
        />
      )}
      {answer.country && (
        <StaticInput
          label={intl.formatMessage(messages.countryLabel)}
          value={intl.formatDisplayName(answer.country, { type: "region" })}
        />
      )}
      {role && (
        <StaticInput
          label={intl.formatMessage(messages.roleLabel)}
          value={role.text}
        />
      )}
      {answer.ambassadorCountry && (
        <StaticInput
          label={intl.formatMessage(messages.ambassadorCountryLabel)}
          value={intl.formatDisplayName(answer.ambassadorCountry, {
            type: "region",
          })}
        />
      )}
      {answer.governmentCompany && (
        <StaticInput
          label={intl.formatMessage(messages.governmentCompanyNameLabel)}
          value={answer.governmentCompany}
        />
      )}
      {answer.governmentCompanyRole && (
        <StaticInput
          label={intl.formatMessage(messages.governmentCompanyRoleLabel)}
          value={answer.governmentCompanyRole}
        />
      )}
      {answer.internationalOrganisation && (
        <StaticInput
          label={intl.formatMessage(
            messages.internationalOrganisationNameLabel
          )}
          value={answer.internationalOrganisation}
        />
      )}
      {answer.internationalOrganisationRole && (
        <StaticInput
          label={intl.formatMessage(
            messages.internationalOrganisationRoleLabel
          )}
          value={answer.internationalOrganisationRole}
        />
      )}
      {militaryRank && (
        <StaticInput
          label={intl.formatMessage(messages.militaryGradeLabel)}
          value={militaryRank.text}
        />
      )}
    </div>
  );
};

interface StaticInputProps {
  label: string;
  value: string | React.ReactNode;
}

const StaticInput: FunctionComponent<StaticInputProps> = ({ label, value }) => {
  return (
    <div className="static-input">
      <div className="label">{label}</div>
      <div className="value">{value}</div>
    </div>
  );
};
