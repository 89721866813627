import React, {
  FunctionComponent,
  useRef,
  useCallback,
  useContext,
} from "react";
import {
  Form,
  RadioGroup,
  RequiredValidator,
  Button,
  LysaFormRef,
  Card,
} from "@lysaab/ui-2";
import { PepType, PepContext, EntityType } from "../PepContext";
import { useIntl, FormattedMessage } from "react-intl";
import { messages } from "./IsPepMessages";

export interface QuestionsProps {
  ownerName?: string;
  entityType?: EntityType;
  type?: PepType;
  onChange: (type: PepType) => void;
  resetValues?: () => void;
}

export const IsPepQuestion: React.FC<QuestionsProps> = ({
  ownerName,
  entityType,
  type,
  onChange,
  resetValues,
}) => {
  const intl = useIntl();

  const IsPepAlternatives = [
    {
      text: intl.formatMessage(messages[PepType.NOT_PEP], {
        entityType,
        name: ownerName,
      }),
      value: PepType.NOT_PEP,
    },
    {
      text: intl.formatMessage(messages[PepType.ME], {
        entityType,
        name: ownerName,
      }),
      value: PepType.ME,
    },
    {
      text: intl.formatMessage(messages[PepType.RELATIVE], {
        entityType,
        name: ownerName,
      }),
      value: PepType.RELATIVE,
    },
    {
      text: intl.formatMessage(messages[PepType.COLLEAGUE], {
        entityType,
        name: ownerName,
      }),
      value: PepType.COLLEAGUE,
    },
  ];

  return (
    <RadioGroup
      alternatives={IsPepAlternatives}
      onChange={(alternative) => {
        onChange(alternative.value);
        resetValues?.();
      }}
      value={IsPepAlternatives.find((alt) => alt.value === type)}
      header={intl.formatMessage(messages.header, {
        entityType,
        name: ownerName,
      })}
      validators={[
        new RequiredValidator(intl.formatMessage(messages.required)),
      ]}
      data-test-id="pep-ispep"
    />
  );
};

interface Props {
  next: () => void;
}

export const IsPepPage: FunctionComponent<Props> = ({ next }) => {
  const pepContext = useContext(PepContext);
  const formRef = useRef<LysaFormRef>();

  const nextPage = useCallback(() => {
    if (formRef.current && formRef.current.isValid) {
      next();
    }
  }, [formRef, next]);

  return (
    <Form onSubmit={nextPage} lysaFormRef={formRef}>
      <Card>
        <IsPepQuestion
          ownerName={pepContext.state.ownerName}
          entityType={pepContext.state.legalEntityType || EntityType.PERSON}
          type={pepContext.state.type}
          onChange={(type) => pepContext.setState({ type })}
        />
      </Card>

      <Button
        label={<FormattedMessage id="pep.ispep.next" />}
        block
        type="submit"
      />
    </Form>
  );
};
