import React, { useState, useContext, useEffect, useRef } from "react";
import { Story, WorldCountry } from "@lysaab/ui-2";
import { IsPepPage } from "../pages/IsPep/IsPepPage";
import { StatusPage } from "../pages/StatusPage/StatusPage";
import { RolePage } from "../pages/RolePage/RolePage";
import { PepContext, PepType, EntityType } from "../pages/PepContext";
import { Done } from "./Done";
import { RelationPage } from "../pages/RelationPage/RelationPage";
import { Localization } from "./Localization";
import { Country, Language, LocalizationContext } from "./LocalizationContext";

export function Pep() {
  const [page, setPage] = useState(0);
  const pepContext = useContext(PepContext);
  const localizationContext = useContext(LocalizationContext);
  const type = pepContext.state.type;
  const loaded = useRef(false);

  useEffect(() => {
    if (!loaded.current) {
      pepContext.setState({
        ownerName: "Sean Banan",
        tin: "191212121212",
        legalEntityType: EntityType.PERSON,
      });
      localizationContext.setState({
        country: Country.DK,
        language: Language.ENGLISH,
      });
      loaded.current = true;
    }
  }, [loaded, pepContext, localizationContext]);

  const countryCode = localizationContext.state.country || Country.DK;
  const countryNames = {
    [Language.ENGLISH]: {
      [Country.DE]: "Germany",
      [Country.DK]: "Denmark",
      [Country.SE]: "Sweden",
      [Country.FI]: "Finland",
    },
    [Language.SWEDISH]: {
      [Country.DE]: "Tyskland",
      [Country.DK]: "Danmark",
      [Country.SE]: "Sverige",
      [Country.FI]: "Finland",
    },
    [Language.GERMAN]: {
      [Country.DE]: "Deutschland",
      [Country.DK]: "Dänemark",
      [Country.SE]: "Schweden",
      [Country.FI]: "Finnland",
    },
  };

  return (
    <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
      <Localization>
        <Story
          header="PEP Lib"
          showBack={page > 0}
          showClose={false}
          onBack={() => {
            if (page === 4 && type === PepType.NOT_PEP) {
              setPage(0);
            } else if (page === 2 && type === PepType.ME) {
              setPage(0);
            } else {
              setPage(page - 1);
            }
          }}
          transitionKey={page.toString()}
          onExit={() => {}}
          ariaLabelProgress={() => "Progress"}
        >
          {page === 0 && (
            <IsPepPage
              next={() => {
                if (type === PepType.ME) {
                  setPage(2);
                } else if (type === PepType.NOT_PEP) {
                  setPage(4);
                } else {
                  setPage(1);
                }
              }}
            />
          )}
          {page === 1 && <RelationPage next={() => setPage(2)} />}
          {page === 2 && (
            <StatusPage
              countryName={
                countryNames[localizationContext.state.language][countryCode]
              }
              countryCode={countryCode as unknown as WorldCountry}
              language={localizationContext.state.language}
              next={() => setPage(3)}
            />
          )}
          {page === 3 && (
            <RolePage
              language={localizationContext.state.language}
              next={() => setPage(4)}
            />
          )}
          {page === 4 && <Done />}
        </Story>
        <hr />
        <fieldset>
          <legend>Konfig</legend>
          <div>
            <label>
              Entitet
              <select
                value={pepContext.state.legalEntityType || EntityType.PERSON}
                onChange={(event) => {
                  pepContext.setState({
                    legalEntityType: event.currentTarget.value as EntityType,
                  });
                }}
              >
                <option value={EntityType.PERSON}>Person</option>
                <option value={EntityType.CORPORATION}>Företag</option>
              </select>
            </label>
          </div>
          <div>
            <label>
              Namnet på den verkliga huvudmannen
              <input
                type="text"
                value={pepContext.state.ownerName || ""}
                onChange={(event) =>
                  pepContext.setState({ ownerName: event.currentTarget.value })
                }
              />
            </label>
          </div>
          <div>
            <label>
              Personnummer på huvudmannen
              <input
                type="text"
                value={pepContext.state.tin || ""}
                onChange={(event) =>
                  pepContext.setState({ tin: event.currentTarget.value })
                }
              />
            </label>
          </div>
          <div>
            <label>
              Språk
              <select
                value={localizationContext.state.language}
                onChange={(event) => {
                  localizationContext.setState({
                    language: event.currentTarget.value as Language,
                  });
                }}
              >
                <option value={Language.ENGLISH}>Engelska</option>
                <option value={Language.SWEDISH}>Svenska</option>
                <option value={Language.GERMAN}>German</option>
              </select>
            </label>
          </div>
        </fieldset>
      </Localization>
    </div>
  );
}
