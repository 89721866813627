import React from "react";
import { LocalizationContextProvider } from "./demo/LocalizationContext";
import { Pep } from "./demo/Pep";
import { PepContextProvider } from "./pages/PepContext";
import "./App.scss";

const App: React.FC = () => {
  return (
    <LocalizationContextProvider>
      <PepContextProvider>
        <Pep />
      </PepContextProvider>
    </LocalizationContextProvider>
  );
};

export default App;
