import React, { FunctionComponent, useReducer } from "react";

export enum Language {
  SWEDISH = "sv",
  ENGLISH = "en",
  GERMAN = "de",
}

export enum Country {
  DE = "DE",
  SE = "SE",
  DK = "DK",
  FI = "FI",
}

// Types for the state
export interface LocalizationState {
  language: Language;
  country?: Country;
}

// All types for the context. This includes setter methods
export interface LocalizationContextProps {
  state: LocalizationState;
  setState: (newState: Partial<LocalizationState>) => void;
  getLocale: () => string;
}

// Create the context. (This will just create a template)
export const LocalizationContext =
  React.createContext<LocalizationContextProps>({} as LocalizationContextProps);

function stateReducer(
  state: LocalizationState,
  newState: Partial<LocalizationState>
) {
  return { ...state, ...newState };
}

export const LocalizationContextProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [state, setState] = useReducer(stateReducer, {
    language: Language.ENGLISH,
  });

  const getLocale = () => {
    let locale: string = state.language;
    if (state.country) {
      locale = `${state.language}-${state.country}`;
    }

    return locale;
  };

  return (
    <LocalizationContext.Provider value={{ state, setState, getLocale }}>
      {children}
    </LocalizationContext.Provider>
  );
};
