import React, { useContext } from "react";
import { PepContext } from "../pages/PepContext";
import { stateToRequest } from "../model/PepModel";
import { PepAnswers } from "../components/PepAnswers";

export function Done() {
  const pepContext = useContext(PepContext);
  return (
    <div>
      <h2>Taco hej!</h2>
      <p>Leverpastej!</p>
      <PepAnswers
        answer={{
          ...stateToRequest(pepContext.state),
          ownerName: pepContext.state.ownerName,
        }}
      />
      <pre>{JSON.stringify(stateToRequest(pepContext.state), null, 2)}</pre>
    </div>
  );
}
