import React, { useContext, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { Language, LocalizationContext } from "./LocalizationContext";
import svMessages from "../locales/sv.json";
import enMessages from "../locales/en.json";
import deMessages from "../locales/de.json";
import { enGB, sv, de } from "date-fns/esm/locale";
import { registerLocale, setDefaultLocale } from "react-datepicker";

/**
 * Setup locales for all datepickers. We use the country code
 * as the key for the datepicker locale data
 */
registerLocale(Language.SWEDISH, sv);
registerLocale(Language.ENGLISH, enGB);
registerLocale(Language.GERMAN, de);

console.log("sv", sv);

declare global {
  interface Window {
    setLanguage?: (lang: Language) => void;
  }
}

const languagePacks = {
  [Language.ENGLISH]: enMessages,
  [Language.SWEDISH]: svMessages,
  [Language.GERMAN]: deMessages,
};

const DEFAULT_LOCALE = "en";

export const Localization: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const localizationContext = useContext(LocalizationContext);

  useEffect(() => {
    /**
     * Change datepicker locale when language is changed
     */
    setDefaultLocale(localizationContext.state.language);
    console.log(
      "datepicker locale change to",
      localizationContext.state.language
    );
  }, [localizationContext.state.language]);

  useEffect(() => {
    window.setLanguage = (lang: Language) => {
      localizationContext.setState({ language: lang });
    };
    return () => {
      delete window.setLanguage;
    };
  }, [localizationContext]);

  return (
    <IntlProvider
      locale={localizationContext.getLocale()}
      defaultLocale={DEFAULT_LOCALE}
      messages={languagePacks[localizationContext.state.language] as any}
    >
      {children}
    </IntlProvider>
  );
};
