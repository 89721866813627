import { defineMessages } from "react-intl";
import { PepType } from "../PepContext";

export const messages = defineMessages({
  header: { id: "pep.ispep.header" },
  required: { id: "pep.ispep.required" },
  [PepType.NOT_PEP]: { id: "pep.ispep.NOT_PEP" },
  [PepType.ME]: { id: "pep.ispep.ME" },
  [PepType.RELATIVE]: { id: "pep.ispep.RELATIVE" },
  [PepType.COLLEAGUE]: { id: "pep.ispep.COLLEAGUE" },
});
