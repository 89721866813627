import { defineMessages } from "react-intl";
import { Role, MilitaryRank } from "../PepContext";

export const roleMessages = defineMessages({
  header: {
    id: "pep.role.roles.header",
  },
  placeholder: {
    id: "pep.role.roles.placeholder",
  },
  required: {
    id: "pep.role.roles.required",
  },
  [Role.HEAD_OF_GOVERNMENT]: {
    id: "pep.role.roles.alt.HEAD_OF_GOVERNMENT",
  },
  [Role.MINISTER]: {
    id: "pep.role.roles.alt.MINISTER",
  },
  [Role.PARLIMENT_MEMBER]: {
    id: "pep.role.roles.alt.PARLIMENT_MEMBER",
  },
  [Role.CENTRAL_BANK_MEMBER]: {
    id: "pep.role.roles.alt.CENTRAL_BANK_MEMBER",
  },
  [Role.BOARD_MEMBER_POLITICAL_PARTY]: {
    id: "pep.role.roles.alt.BOARD_MEMBER_POLITICAL_PARTY",
  },
  [Role.JUDGE_SUPREME_COURT]: {
    id: "pep.role.roles.alt.JUDGE_SUPREME_COURT",
  },
  [Role.JUDGE_OTHER_HIGH_COURT]: {
    id: "pep.role.roles.alt.JUDGE_OTHER_HIGH_COURT",
  },
  [Role.REVISION_INSTITUTE]: {
    id: "pep.role.roles.alt.REVISION_INSTITUTE",
  },
  [Role.AMBASSADOR]: {
    id: "pep.role.roles.alt.AMBASSADOR",
  },
  [Role.MILITARY_OFFICER]: {
    id: "pep.role.roles.alt.MILITARY_OFFICER",
  },
  [Role.GOVERNMENT_COMPANY]: {
    id: "pep.role.roles.alt.GOVERNMENT_COMPANY",
  },
  [Role.EU_HIGH_RANKING_POSITION]: {
    id: "pep.role.roles.alt.EU_HIGH_RANKING_POSITION",
  },
  [Role.HIGH_RANKING_POSITION_INTERNALTIONAL_CORP]: {
    id: "pep.role.roles.alt.HIGH_RANKING_POSITION_INTERNALTIONAL_CORP",
  },
});

export const militaryMessages = defineMessages({
  header: {
    id: "pep.role.military.name.header",
  },
  placeholder: {
    id: "pep.role.military.header",
  },
  required: {
    id: "pep.role.military.required",
  },
  [MilitaryRank.GENERAL]: {
    id: "pep.role.military.alt.GENERAL",
  },
  [MilitaryRank.LIEUTENANT_GENERAL]: {
    id: "pep.role.military.alt.LIEUTENANT_GENERAL",
  },
  [MilitaryRank.MAJOR_GENERAL]: {
    id: "pep.role.military.alt.MAJOR_GENERAL",
  },
  [MilitaryRank.ADMIRAL]: {
    id: "pep.role.military.alt.ADMIRAL",
  },
  [MilitaryRank.VICE_ADMIRAL]: {
    id: "pep.role.military.alt.VICE_ADMIRAL",
  },
  [MilitaryRank.REAR_ADMIRAL]: {
    id: "pep.role.military.alt.REAR_ADMIRAL",
  },
});

export const ambassadorMessages = defineMessages({
  header: {
    id: "pep.role.ambassador.header",
  },
  required: {
    id: "pep.role.ambassador.required",
  },
  placeholder: {
    id: "pep.role.ambassador.placeholder",
  },
});

export const governmentCompanyMessages = defineMessages({
  nameHeader: {
    id: "pep.role.governmentCompany.name.header",
  },
  nameRequired: {
    id: "pep.role.governmentCompany.name.required",
  },
  roleHeader: {
    id: "pep.role.governmentCompany.role.header",
  },
  roleRequired: {
    id: "pep.role.governmentCompany.role.required",
  },
});

export const intCompanyMessages = defineMessages({
  nameHeader: {
    id: "pep.role.intCompany.name.header",
  },
  nameRequired: {
    id: "pep.role.intCompany.name.required",
  },
  roleHeader: {
    id: "pep.role.intCompany.role.header",
  },
  roleRequired: {
    id: "pep.role.intCompany.role.required",
  },
});

export const countrySelectionMessages = defineMessages({
});
