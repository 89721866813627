import { defineMessages } from "react-intl";
import { PepStatus } from "../PepContext";

export const isOngoingMessages = defineMessages({
  header: {
    id: "pep.status.isongoing.header",
  },
  required: {
    id: "pep.status.isongoing.required",
  },
});

export const endedMessages = defineMessages({
  header: {
    id: "pep.status.ended.header",
  },
  required: {
    id: "pep.status.ended.required",
  },
});

export const isLocalCountryMessages = defineMessages({
  header: {
    id: "pep.status.localcountry.header",
  },
  required: {
    id: "pep.status.localcountry.required",
  },
});

export const countrySelectionMessages = defineMessages({
  header: {
    id: "pep.status.countryselecton.header",
  },
  required: {
    id: "pep.status.countryselecton.required",
  },
  placeholder: {
    id: "pep.status.countryselection.placeholder"
  }
});

export const localCountryAltMessages = defineMessages({
  localCountry: {
    id: "pep.status.localcountryalt.local",
  },
  otherCountry: {
    id: "pep.status.othercountryalt.other",
  },
});

export const stateAltMessages = defineMessages({
  [PepStatus.ONGOING]: {
    id: "pep.status.state.ONGOING",
  },
  [PepStatus.ENDED]: {
    id: "pep.status.state.ENDED",
  },
});
