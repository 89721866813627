import { defineMessages } from "react-intl";
import { BusinessRelation, Relation } from "../PepContext";

export const relationMessages = defineMessages({
  header: {
    id: "pep.relation.relationship.header",
  },
  required: {
    id: "pep.relation.relation.required",
  },
  [Relation.MARRIED_PARTNER]: {
    id: "pep.relation.relation.MARRIED_PARTNER",
  },
  [Relation.COLIVING_PARTNER]: {
    id: "pep.relation.relation.COLIVING_PARTNER",
  },
  [Relation.CHILD]: {
    id: "pep.relation.relation.CHILD",
  },
  [Relation.PARENT]: {
    id: "pep.relation.relation.PARENT",
  },
  [Relation.CHILD_MARRIED_PARTNER]: {
    id: "pep.relation.relation.CHILD_MARRIED_PARTNER",
  },
  [Relation.CHILD_COLIVING_PARTNER]: {
    id: "pep.relation.relation.CHILD_COLIVING_PARTNER",
  },
});

export const businessRelationMessages = defineMessages({
  header: {
    id: "pep.relation.businessrelation.header",
  },
  required: {
    id: "pep.relation.businessrelation.required",
  },
  [BusinessRelation.CO_OWNERSHIP]: {
    id: "pep.relation.businessrelation.alt.CO_OWNERSHIP",
  },
  [BusinessRelation.CLOSE_CONNECTION]: {
    id: "pep.relation.businessrelation.alt.CLOSE_CONNECTION",
  },
  [BusinessRelation.COMPANY_FOR_PEP]: {
    id: "pep.relation.businessrelation.alt.COMPANY_FOR_PEP",
  },
});

export const relativeNameMessages = defineMessages({
  header: {
    id: "pep.relation.relative.name.header",
  },
  required: {
    id: "pep.relation.relative.name.required",
  },
});

export const birthdayMessages = defineMessages({
  header: {
    id: "pep.relation.birthday.header",
  },
  required: {
    id: "pep.relation.birthday.required",
  },
});

export const colleagueNameMessages = defineMessages({
  header: {
    id: "pep.relation.colleague.name.header",
  },
  required: {
    id: "pep.relation.colleague.name.required",
  },
});
